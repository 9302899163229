import React from 'react';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class TheWayOfTheEmptyHand extends React.Component {
    render() {
        return (
            <Layout>
                <h1>The way of the empty hand, Sheffield City Press UK 1988</h1>
                <StaticImage src="../../../images/thewayoftheemptyhand/ResizedImage600661-7220274.jpg"
                             alt="The way of the empty hand"/>
            </Layout>);
    }
}

export default TheWayOfTheEmptyHand;
